<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="@/assets/images/logo/logo_sob.svg"
            height="70"
            width="80"
            alt=""
            srcset=""
          >
          <img
            src="@/assets/images/logo/deno.svg"
            height="40"
            width="103"
            class="mt-1"
            alt=""
            srcset=""
          >
        </b-link>

        <!-- form -->
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="signIn"
        >
          <!-- email -->
          <b-form-group
            label-for="email"
            label="Identifiant"
          >
            <b-form-input
              id="email"
              v-model="$v.user.username.$model"
              :class="{ 'is-invalid': $v.user.username.$error }"
              name="login-email"
              placeholder="toto@sobemap.com"
              autofocus
            />
            <div
              v-if="$v.user.username.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.user.username.required"
              >L'identifiant est requis .</span>
            </div>
          </b-form-group>

          <!-- password -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">Mot de passe</label>
              <b-link :to="{ name: 'forgot-password' }">
                <small>Mot de passe oublié?</small>
              </b-link>
            </div>

            <b-input-group
              class="input-group-merge"
              :class="{ 'is-invalid': $v.user.password.$error }"
            >
              <b-form-input
                id="password"
                v-model="$v.user.password.$model"
                :type="passwordFieldType"
                class="form-control-merge"
                :class="{ 'is-invalid': $v.user.password.$error }"
                name="login-password"
                placeholder="**********"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <div
              v-if="$v.user.password.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.user.password.required"
              >Mot de passe requis.</span>
            </div>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            type="submit"
            :disabled="process"
            block
          >
            <b-spinner
              v-if="process"
              small
            />
            <span v-show="!process">SE CONNECTER</span>
          </b-button>
        </b-form>

        <div class="divider my-2">
          <div class="divider-text text-success font-weight-bold h5">
            SOBEMAP
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    // BSV
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        username: null,
        password: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      process: 'loginProcess',
      error: 'loginError',
      success: 'loginSuccess',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Connexion',
            text: 'Connexion faite avec succes.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        window.location.reload()
        setTimeout(() => {
          this.$router.push('/app/dashboard')
        }, 400)
      }
    },
    error(val) {
      if (val) {
        if (val === 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Connexion',
              text: 'Veuillez confirmer votre e-mail pour continuer.',
              icon: 'MailIcon',
              variant: 'danger',
            },
          })
          return
        }
        switch (val.code) {
          case 405:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Connexion',
                text: 'Email ou Mot de passe erroné',
                icon: 'UserXIcon',
                variant: 'warning',
              },
            })
            break

          default:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Connexion',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            break
        }
      }
    },
  },
  mounted() {
  },
  validations: {
    user: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(['loginUser', 'registAdmin']),
    registerAdmin() {
      this.registAdmin()
    },
    signIn() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.loginUser({
        username: this.user.username,
        password: this.user.password,
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
